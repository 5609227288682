<template>
    <div class="wrap-content mb-4 mt-4">
        <br><br>
        <v-layout
            align-space-around
            justify-center
            row
            fill-height
            class="dashboard-box text-xs-center">
            <v-flex xs6>
                <h1>Oops!</h1>
                <p>
                    Advanced reporting with lead and unit sale tracking in only available
                    <br>
                    through our
                    <a
                        href="https://buyerbridge.io/inventory-ads-demo/"
                        target="_blank">
                        Facebook Automotive Inventory Ads solutions
                    </a>.
                </p>
                <p>
                    Ready to get started? Click the upgrade button below to begin the process!
                    <br>
                    <br>
                    <upgrade-button :dealer="dealer">
                        Upgrade Now
                    </upgrade-button>
                    <br>
                </p>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
import UpgradeButton from './UpgradeButton';
import { mapState } from 'vuex';

export default {
    components: {
        UpgradeButton
    },
    computed: {
        ...mapState({
            dealer: (state) => state.dealer.currentDealer
        })
    }
};
</script>
