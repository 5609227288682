<template>
    <styled-slideout
        :value="value"
        :width="700"
        @input="$emit('input', $event)">
        <div class="pa-5">
            <h2>Offiline Events</h2>
            <p class="mb-4 dialog-header-description">
                Aute anim eu nisi Lorem elit anim nostrud ea laboris mollit. Sit esse
                aliquip nulla veniam magna. Qui et quis elit tempor veniam dolore
                nostrud non adipisicing velit et. Lorem consectetur nulla incididunt
                aliqua magna dolore occaecat duis est culpa proident adipisicing amet
                laborum.
            </p>
            <video
                width="100%"
                height="350"
                controls
                autoplay>
                <source
                    src="https://youtu.be/wsNvPUr-bJg"
                    type="video/mp4">
                Your browser does not support the video tag.
            </video>
            <div class="related-articles-wrapper pt-5">
                <h2>Related Support Articles</h2>
                <div class="mt-3 ml-5">
                    <p><a>How To Use The New Offline Event Uploader Tool</a></p>
                    <p><a>How To Use The New Offline Event Uploader Tool</a></p>
                </div>
            </div>
        </div>
    </styled-slideout>
</template>

<script>
import StyledSlideout from '@/components/globals/StyledSlideout';

export default {
  components: {
    StyledSlideout,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
<style scoped>
.dialog-header-description {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: #4a4a4a;
}
</style>