const emailDictionary = ['email'];
const phoneDictionary = ['home phone', 'work phone', 'cell phone', 'cell'];

export default [
    {
        name: 'Full Name',
        field: 'full_name',
        description:
            'The user\'s full name.  When supplied our system will attempt to split out the first & last name.  This will override the first_name & last_name fields if they can be properly parsed.',
        required: 'Required if first_name & last_name are missing',
        dictionary: ['full name', 'customer', 'contact'],
        match: null
    },
    {
        name: 'First Name',
        field: 'first_name',
        description: 'The user\'s first name',
        required: 'Required if full_name is missing',
        dictionary: ['first','first name', 'fn'],
        match: null
    },
    {
        name: 'Last Name',
        field: 'last_name',
        description: 'The user\'s last name',
        required:
            'Required if full_name is missing or if first name is supplied',
        dictionary: ['last', 'last name', 'ln'],
        match: null
    },
    {
        name: 'Email',
        field: 'email',
        description: 'The user\'s primary email',
        required: 'Required if no phone is supplied',
        dictionary: emailDictionary,
        match: 'email'
    },
    {
        name: 'Email 2',
        field: 'email_2',
        description: 'An additional email to help with matching',
        required: 'Recommended',
        dictionary: emailDictionary,
        match: 'email'
    },
    {
        name: 'Email 3',
        field: 'email_3',
        description: 'An additional email to help with matching',
        required: 'Recommended',
        dictionary: emailDictionary,
        format: 'email'
    },
    {
        name: 'Phone',
        field: 'phone',
        description: 'The user\'s primary phone number',
        required: 'Required is no email is supplied',
        dictionary: phoneDictionary,
        match: 'phone'
    },
    {
        name: 'Phone 2',
        field: 'phone_2',
        description: 'An additional phone number to help with matching',
        required: 'Recommended',
        dictionary: phoneDictionary,
        match: 'phone'
    },
    {
        name: 'Phone 3',
        field: 'phone_3',
        description: 'An additional phone number to help with matching',
        required: 'Recommended',
        dictionary: phoneDictionary,
        match: 'phone'
    },
    {
        name: 'Date of Birth',
        field: 'date_of_birth',
        description: 'The user\'s date of birth.  This can be a critical field for matching.',
        required: 'Recommended',
        dictionary: ['date of birth', 'birthday', 'dob'],
        match: null
    },
    {
        name: 'Street',
        field: 'street',
        description: 'The user\'s primary street address',
        required: 'Recommended',
        dictionary: ['street', 'address'],
        match: null

    },
    {
        name: 'City',
        field: 'city',
        description: 'The user\'s primary city',
        required: 'Recommended',
        dictionary: ['city', 'ct'],
        match: null
    },
    {
        name: 'State',
        field: 'state',
        description: 'The user\'s primary state',
        required: 'Required if Limited Data Use is active',
        dictionary: ['state', 'region', 'st'],
        match: null
    },
    {
        name: 'Country',
        field: 'country',
        description: 'The user\'s primary country',
        required: 'Dealer\'s country is used if not set',
        dictionary: ['country', 'ct'],
        match: null
    },
    {
        name: 'Postal Code',
        field: 'postal_code',
        description: 'The user\'s primary postal code',
        required: 'Recommended',
        dictionary: ['postal code', 'postal', 'zip'],
        match: null
    },
    {
        name: 'Date',
        field: 'date',
        description:
            'The date that the event occurred (e.g. the date of the sale or date the lead was received) and cannot be older than 62 days.  It will default to today if not supplied.',
        required: 'Recommended',
        dictionary: ['date', 'created', 'time', 'sold date'],
        match: null
    },
    {
        name: 'VIN',
        field: 'vin',
        description:
            'The VIN of the associated vehicle (e.g. the vehicle sold or the vehicle the lead was from)',
        required: 'No',
        dictionary: ['vin'],
        match: null
    },
    {
        name: 'Value',
        field: 'value',
        description:
            'The value of the transaction in your local currency (e.g. the value of the vehicle or assumed value of the lead)',
        required: 'No',
        dictionary: ['value'],
        match: null
    },
    {
        name: 'Currency',
        field: 'currency',
        description:
            'The currency of the transaction.  Defaults to the Dealer\'s country\'s currency if not defined',
        required: 'No',
        dictionary: ['currency'],
        match: null
    },
];