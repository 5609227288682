<template>
    <div
        :style="{position:'relative'}"
        class="my-5">
        <h3 class="mb-3">
            Map your data
        </h3>
        <p>Preview your uploaded data in the table below to ensure the column headers are properly mapped. Review our field descriptions for details on supported target fields and how they can impact your match rate</p>
        <div
            class="layout justify-end">
            <styled-tooltip
                v-model="openMappedColumnsDialog"
                :open-on-click="true">
                <template #content>
                    <div class="mapped-columns">
                        <p>Columns to map</p>
                        <v-layout class="layout column dealers-onboarding-checkbox grey--text">
                            <v-flex
                                v-for="(header, index) in mappedColumns"
                                :key="index">
                                <v-list-tile-content>
                                    <v-checkbox
                                        :key="index"
                                        v-model="mappedColumns[index].notMapped"
                                        :label="header.header"
                                        :value="header.notMapped"
                                        :false-value="true"
                                        :true-value="false"
                                        class="ma-0 mr-4 py-0"
                                        @change="onToggleMapping($event, index)" />
                                </v-list-tile-content>
                            </v-flex>
                        </v-layout>
                    </div>
                </template>
                <button
                    class="map-colum-setting-icon"
                    @click="openMappedColumnsDialog = true">
                    <i
                        class="material-icons setting-icon">
                        settings
                    </i>
                </button>
            </styled-tooltip>
        </div>
        <div
            :style="{
                position: 'relative', 
                overflow: 'scroll'
            }">
            <table
                class="styled-table-native">
                <thead>
                    <tr
                        class="your-columns">
                        <th
                            align="left"
                            :colspan="headers.length - 1">
                            Your columns for file - {{ fileName }}
                        </th>
                        <th align="right" />
                    </tr>
                    <tr class="file-columns">
                        <th
                            v-for="(header, index) in headers"
                            :key="`${header}-${index}`">
                            {{ header }}
                        </th>
                    </tr>
                    <tr class="mapper-column">
                        <th
                            v-for="(_, index) in headers"
                            :key="index">
                            <div
                                class="mapper-icon" />
                        </th>
                    </tr>
                    
                    <tr
                        class="mapper-column">
                        <th
                            v-for="(header, index) in headers"
                            :key="index">
                            <div
                                v-if="showCustomFieldDialog && customFieldIndex === index"
                                class="custom-field-box">
                                <p>
                                    Custom Name
                                </p>
                                <form
                                    ref="form">
                                    <v-text-field
                                        v-model="customField"
                                        class="ma-3 styled-field" />
                                    <div
                                        class="flex layout row justify-space-around buttons-wrapper">
                                        <styled-button
                                            :style="{ width: '50%' }"
                                            outline
                                            small
                                            @click.prevent="closeCustomColumnBox(index)">
                                            Back
                                        </styled-button>
                                        <styled-button
                                            small
                                            :style="{ width: '50%', marginLeft: '10px' }"
                                            @click.prevent="addCustomField(index)">
                                            OK
                                        </styled-button>
                                    </div>
                                </form>
                            </div>
                            <v-select
                                ref="columnsSelect"
                                :value="mappedColumns[index].field"
                                class="styled-field mapping-field"
                                :class="{ 
                                    'not-mapped-columns': mappedColumns[index].notMapped 
                                }"
                                item-value="field"
                                item-text="name"
                                :items="fieldSelections"
                                :menu-props="{
                                    closeOnContentClick: true,
                                    maxHeight: 360
                                }"
                                return-object
                                @input="onColumnInput($event, index)">
                                <template 
                                    v-if="mappedColumns[index].notMapped || mappedColumns[index].customField"
                                    #prepend-inner>
                                    <div class="v-select__selection v-select__selection--comma">
                                        {{ mappedColumns[index].customField || 'Don\'t Map' }}
                                    </div>
                                </template>
                                <template #append-item>
                                    <v-divider class="mt-2" />
                                    
                                    <v-list-tile @click="onShowCustomFieldDialog(index)">
                                        <v-list-tile-content>
                                            <v-list-tile-title>
                                                <v-icon color="#00A2EA">
                                                    add_circle
                                                </v-icon>
                                                Custom Name
                                            </v-list-tile-title>
                                        </v-list-tile-content>
                                    </v-list-tile>
                                
                                    <v-divider class="mt-2" />
                                    <v-list-tile
                                        @click="dontMapColumn(index)">
                                        <v-list-tile-content>
                                            <v-list-tile-title>
                                                <v-icon color="red">
                                                    cancel
                                                </v-icon>
                                                Don't map
                                            </v-list-tile-title>
                                        </v-list-tile-content>
                                    </v-list-tile>
                                </template>
                            </v-select>
                            <!-- </template> -->
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(row, index) in filteredCsvData"
                        :key="index"
                        :class="[index % 2 ? 'row-even' : 'row-odd']">
                        <td
                            v-for="(header, i) in headers"
                            :key="i">
                            {{ row[i] }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import StyledTooltip from '@/components/globals/StyledTooltip';
import StyledButton from '@/components/globals/StyledButton';
import { autoMap, getKeyByValue } from '@/helpers';
import { isEmpty } from 'lodash';

export default {
    components: {
        StyledButton,
        StyledTooltip,
    },
    props: {
        rows: {
            type: Array,
            default: () => []
        },
        preset: {
          type: String,
          default: 'Default'
        },
        headers: {
            type: Array,
            default: () => []
        },
        fields: {
            type: Array,
            default: () => []
        },
        mapping: {
            type: Object,
            default: () => {},
        },
        fileName: {
            type: String,
            default:''
        }
    },
    data() {
        return {
            mappedColumns: [],
            customFields: [],
            showCustomFieldDialog: false,
            customField: null,
            customFieldIndex: null,
            openMappedColumnsDialog: false,
            localMapping: {},
        };
    },
    computed: {
        filteredCsvData() {
            return this.rows.slice(0,5);
        },
        mappedFields() {

            const mappedFields = [];

            this.mappedColumns.forEach(column => {
                if (column.field) {
                    mappedFields.push(column.field);
                }
            });

            return mappedFields;
        },
        unhandledFields() {
            const unhandledFields = [];
            
            // Find fields that haven't been explicitly mapped (or not)
            this.mappedColumns.forEach(column => {
                if (column.field == null && column.notMapped == false) {
                    unhandledFields.push(column.header);
                }
            });

            return unhandledFields;
            
        },
        fieldSelections() {
            return this.fields.map(field => {

                field.disabled = this.mappedFields.includes(field.field);

                return field;
            });
        },
        /**
         * Format mapping data for the BuyerBridge
         * upload API's format
         */
        formattedMapping() {
            const data = {};

            this.mappedColumns.forEach(column => {

                if (column.customField) {
                    if (typeof data.custom_data !== 'object') {
                        data.custom_data = {};
                    }

                    data.custom_data[column.customField] = column.header;
                } else if (column.field) {
                    data[column.field] = column.header;
                }
            });

            return data;
        }
    },
    watch: {
        formattedMapping(value) {
            this.$emit('update:mapping', value);
        },
        unhandledFields(value) {
            this.$emit('update:unhandled-fields', value);
        }
    },
    created() {
        this.init();
    },
    methods: {
        init() {

            let mapping = { ...this.mapping };

            // If no mapping was supplied auto-detect it
            if (isEmpty(mapping)) {
                mapping = autoMap(this.fields, this.headers, this.preset);
            }

            // Setup the primary handling array of configuration
            // for each header.  This is used throughout the component
            // to track how we're handling each provided header
            this.mappedColumns = this.headers.map(header => {

                const mappedField = getKeyByValue(mapping, header);

                return {
                    header,
                    notMapped: !mappedField,
                    field: mappedField || null,
                    customField: null // @todo handle mapping for custom fields
                };
            });
        },
        onToggleMapping(value, colIndex) {
            // If we're toggling this off set the column to not be mapped
            if (value == true) {
                this.dontMapColumn(colIndex);
            }
        },
        onColumnInput(selection, colIndex) {

            const newColumn = { ...this.mappedColumns[colIndex] };

            newColumn.notMapped = false;
            newColumn.customField = null;
            newColumn.field = selection.field;

            this.$set(this.mappedColumns, colIndex, newColumn);
        },
        closeCustomColumnBox(index) {
            const selectRef = this.$refs.columnsSelect[index];
            selectRef.onClick();
            this.showCustomFieldDialog = false;
        },
        addCustomField(colIndex) {
            const newColumn = { ...this.mappedColumns[colIndex] };

            newColumn.customField = this.customField;
            newColumn.field = null;

            this.$set(this.mappedColumns, colIndex, newColumn);

            this.showCustomFieldDialog = false;
        },
        dontMapColumn(colIndex) {
            const newColumn = { ...this.mappedColumns[colIndex] };

            newColumn.notMapped = true;
            newColumn.field = null;
            newColumn.customField = null;

            this.$set(this.mappedColumns, colIndex, newColumn);
        },
        onShowCustomFieldDialog(colIndex) {
            this.showCustomFieldDialog = true;
            this.customField = this.headers[colIndex];
            this.customFieldIndex = colIndex;
        },
    },
};
</script>

<style lang="scss">
.your-columns {
    background-color: $blue-100;
    border-bottom: 2px solid white;
    th {
        font-weight: 700;
        color: $light-grey-title;
        font-size: 16px;
        width: 100%;
    }
}
.file-columns {
    background-color: $blue-100;
    border-bottom: 2px solid gray;
    th {
        border-right: 2px solid white;
    }
}
.mapper-column {
    th {
        width: 10%;
        padding-top:0px;
    }
}
.mapped-columns {
    height: 600px;
    overflow: auto;
    padding: 0 20px;

    p {
        font-size: 14px;

    }
}
.mapper-icon { 
    background-image: url('/img/arrow-down-2.svg');
    height: 24px;
    width: 24px;
    margin-top: 15px;
    text-align: center;
}
.map-colum-setting-icon {
    position: absolute;
    right: 20px;
    top:75px;
    z-index: 2;
}
.v-btn {
    &.custom-button {
        width: 42px;
        min-width: 36px;
    }
  
}
.custom-field-box {
    position:absolute; 
    z-index:10; 
    height:auto; 
    top:170px; 
    width:20%; 
    background-color:white; 
    border:1px solid #DADADA;
    p {
        border-bottom:1px solid #DADADA;
        height:40px;
        padding:10px;
    }
    
    .buttons-wrapper {
        border-top:1px solid  #DADADA;
        padding:10px;
    }
    .custom-field-input {
        border:1px solid #DADADA; height:32px;
        text-align: center;
        color:#747474;
    }
}
.not-mapped-columns.styled-field.v-input.v-text-field.v-input.v-text-field  { 
    .v-select__selection {
        color:red !important;
    }
}
.setting-icon{
    opacity: 0.5;
}

.mapping-field.styled-field.v-input.v-text-field.v-input.v-text-field {
    .v-select__selection {
        font-size: 14px;
        font-weight: normal;
    }
}

</style>
