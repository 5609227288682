<template>
    <div class="d-inline-block">
        <styled-dialog v-model="dialogOpen">
            <template #heading>
                <h2>Notification Emails</h2>
            </template>

            <div class="mb-4 mt-3">
                <v-alert
                    :value="!emails.length"
                    type="error"
                    class="mb-4">
                    You need to have at least one notification email
                </v-alert>

                <div
                    v-for="(email, index) in emails"
                    :key="`${email}-${index}`"
                    class="email-item mb-2">
                    <span>{{ email }}</span>
                    <button @click.prevent="emails.splice(index, 1)">
                        <icon
                            name="close"
                            color="white"
                            size="15" />
                    </button>
                </div>
            </div>

            <v-form
                ref="form"
                v-model="valid"
                class="new-email-form layout mb-5"
                @submit.prevent="handleNewEmailSubmit">
                <v-text-field
                    v-model="newEmail"
                    label="Enter additional email"
                    class="styled-field"
                    :rules="validationRules" />
                <styled-button
                    :disabled="!valid"
                    class="ml-3"
                    small
                    outline>
                    ADD EMAIL
                </styled-button>
            </v-form>


            <div class="layout align-center">
                <styled-button
                    class="ml-auto"
                    :disabled="!emails.length"
                    @click="dialogOpen = false">
                    DONE
                </styled-button>
            </div>
        </styled-dialog>
        <span v-if="emails.length && showCurrentUserEmail">
            {{ emails[0] }}
        </span>
        <span v-if="emails.length > 1">
            &amp; {{ emails.length - 1 }} more
        </span>
        &nbsp;
        <a
            @click.stop="dialogOpen = true">
            <span v-if="customText">{{ customText }}</span>
            <span v-else>edit</span>
        </a>
    </div>
</template>

<script>
import colors from '@/helpers/colors';
import Icon from '@/components/globals/Icon';

import StyledButton from '@/components/globals/StyledButton';
import StyledDialog from '@/components/globals/StyledDialog';
import { requiredRules, emailRules } from '@/helpers/validationRules';

import { mapState } from 'vuex';

export default {
    components: {
        Icon,
        StyledButton,
        StyledDialog
    },
    props: {
        value: {
            type: Array,
            required: true
        },
        customText:{
            type:String,
            required:false,
        },
        showCurrentUserEmail: {
            type:Boolean,
            required:false,
            default:true,
        }
    },
    data() {
        return {
            valid: true,
            colors,
            validationRules: [
                ...requiredRules,
                ...emailRules
            ],
            dialogOpen: false,
            newEmail: '',
            emails: []
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user.user
        })
    },
    watch: {
        emails: {
            handler(emails) {
                // Convert to an email with a property of email
                const formattedEmails = emails.map(email => {
                    return { email };
                });
                this.$emit('input', formattedEmails);
            },
            immediate: true
        }
    },
    created() {
        this.emails.push(this.user.email);
    },
    destroyed() {
        this.$emit('clear-emails');
    },
    methods: {
        handleNewEmailSubmit() {
            this.emails.push(this.newEmail);
            this.newEmail = '';
            this.$refs.form.resetValidation();
        }
    }
};
</script>

<style lang="scss" scoped>
.new-email-form {
    button {
        height: 34px;
    }
}
.email-item {
    border-radius: 5px;
    background-color: #8F9EA6;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    line-height: 1rem;
    button {
        margin-left: auto;
        display: block;

    }
    /* .v-btn--icon {
        margin-left: auto;
        height: 10px;
    } */
}
</style>
