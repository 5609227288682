<template>
    <v-btn
        depressed
        color="primary"
        class="text--white text-none font-weight-bold border-radius-5 bg-green-gradient my-2"
        :href="mailToUrl"
        target="_blank">
        <slot />
    </v-btn>
</template>

<script>
import moment from 'moment';
import stripIndent from 'strip-indent';

export default {
    props: {
        dealer: {
            type: Object,
            required: true
        }
    },
    computed: {
        mailToUrl() {
            const to = 'upgrades@buyerbridge.io';
            const subject = `Upgrade Account Request - ${this.dealer.name}`;

            const body = stripIndent(`
                Fill out the following information to request your upgrade.
                Our support team will work on upgrading the account within 24 hours of this request.

                Upgrade to Pro or Ultimate?:
                Accelerate Budget?:
                Monthly Budget:
                Targeting Radius (15+ Miles):
                Additional Comments:

                #For Internal Use#
                Dealer name: ${this.dealer.name}
                ID: ${this.dealer.id}
                Reseller: ${this.dealer.agency.name}
                Date of upgrade: ${moment().format('LLLL')}
            `);

            return `mailto:${to}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        }
    }
};
</script>
