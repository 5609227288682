<template>
    <v-alert
        :value="dealerInFacebookDpoJurisdiction && currentDealer.facebook_dpo.includes('LDU')"
        type="error"
        class="flex xs12">
        <div class="layout align-center wrap">
            <div class="flex xs12 md9">
                Limited data usage is active on this account which limits data shared with Facebook
                and can <strong>significantly</strong> reduce the performance of attribution-based
                events like website leads and sales matchbacks.
            </div>
            <div class="flex xs12 md3 text-md-right">
                <styled-button
                    class=""
                    small
                    outline
                    light
                    @click="$emit('click', $event)">
                    ALLOW DATA SHARING
                </styled-button>
            </div>
        </div>
    </v-alert>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import StyledButton from './StyledButton';

export default {
    components: {
        StyledButton
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer
        }),
        ...mapGetters([
            'dealerInFacebookDpoJurisdiction',
        ])
    }
};
</script>
