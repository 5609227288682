var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-5",style:({position:'relative'})},[_c('h3',{staticClass:"mb-3"},[_vm._v(" Map your data ")]),_c('p',[_vm._v("Preview your uploaded data in the table below to ensure the column headers are properly mapped. Review our field descriptions for details on supported target fields and how they can impact your match rate")]),_c('div',{staticClass:"layout justify-end"},[_c('styled-tooltip',{attrs:{"open-on-click":true},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"mapped-columns"},[_c('p',[_vm._v("Columns to map")]),_c('v-layout',{staticClass:"layout column dealers-onboarding-checkbox grey--text"},_vm._l((_vm.mappedColumns),function(header,index){return _c('v-flex',{key:index},[_c('v-list-tile-content',[_c('v-checkbox',{key:index,staticClass:"ma-0 mr-4 py-0",attrs:{"label":header.header,"value":header.notMapped,"false-value":true,"true-value":false},on:{"change":function($event){return _vm.onToggleMapping($event, index)}},model:{value:(_vm.mappedColumns[index].notMapped),callback:function ($$v) {_vm.$set(_vm.mappedColumns[index], "notMapped", $$v)},expression:"mappedColumns[index].notMapped"}})],1)],1)}),1)],1)]},proxy:true}]),model:{value:(_vm.openMappedColumnsDialog),callback:function ($$v) {_vm.openMappedColumnsDialog=$$v},expression:"openMappedColumnsDialog"}},[_c('button',{staticClass:"map-colum-setting-icon",on:{"click":function($event){_vm.openMappedColumnsDialog = true}}},[_c('i',{staticClass:"material-icons setting-icon"},[_vm._v(" settings ")])])])],1),_c('div',{style:({
            position: 'relative', 
            overflow: 'scroll'
        })},[_c('table',{staticClass:"styled-table-native"},[_c('thead',[_c('tr',{staticClass:"your-columns"},[_c('th',{attrs:{"align":"left","colspan":_vm.headers.length - 1}},[_vm._v(" Your columns for file - "+_vm._s(_vm.fileName)+" ")]),_c('th',{attrs:{"align":"right"}})]),_c('tr',{staticClass:"file-columns"},_vm._l((_vm.headers),function(header,index){return _c('th',{key:`${header}-${index}`},[_vm._v(" "+_vm._s(header)+" ")])}),0),_c('tr',{staticClass:"mapper-column"},_vm._l((_vm.headers),function(_,index){return _c('th',{key:index},[_c('div',{staticClass:"mapper-icon"})])}),0),_c('tr',{staticClass:"mapper-column"},_vm._l((_vm.headers),function(header,index){return _c('th',{key:index},[(_vm.showCustomFieldDialog && _vm.customFieldIndex === index)?_c('div',{staticClass:"custom-field-box"},[_c('p',[_vm._v(" Custom Name ")]),_c('form',{ref:"form",refInFor:true},[_c('v-text-field',{staticClass:"ma-3 styled-field",model:{value:(_vm.customField),callback:function ($$v) {_vm.customField=$$v},expression:"customField"}}),_c('div',{staticClass:"flex layout row justify-space-around buttons-wrapper"},[_c('styled-button',{style:({ width: '50%' }),attrs:{"outline":"","small":""},on:{"click":function($event){$event.preventDefault();return _vm.closeCustomColumnBox(index)}}},[_vm._v(" Back ")]),_c('styled-button',{style:({ width: '50%', marginLeft: '10px' }),attrs:{"small":""},on:{"click":function($event){$event.preventDefault();return _vm.addCustomField(index)}}},[_vm._v(" OK ")])],1)],1)]):_vm._e(),_c('v-select',{ref:"columnsSelect",refInFor:true,staticClass:"styled-field mapping-field",class:{ 
                                'not-mapped-columns': _vm.mappedColumns[index].notMapped 
                            },attrs:{"value":_vm.mappedColumns[index].field,"item-value":"field","item-text":"name","items":_vm.fieldSelections,"menu-props":{
                                closeOnContentClick: true,
                                maxHeight: 360
                            },"return-object":""},on:{"input":function($event){return _vm.onColumnInput($event, index)}},scopedSlots:_vm._u([(_vm.mappedColumns[index].notMapped || _vm.mappedColumns[index].customField)?{key:"prepend-inner",fn:function(){return [_c('div',{staticClass:"v-select__selection v-select__selection--comma"},[_vm._v(" "+_vm._s(_vm.mappedColumns[index].customField || 'Don\'t Map')+" ")])]},proxy:true}:null,{key:"append-item",fn:function(){return [_c('v-divider',{staticClass:"mt-2"}),_c('v-list-tile',{on:{"click":function($event){return _vm.onShowCustomFieldDialog(index)}}},[_c('v-list-tile-content',[_c('v-list-tile-title',[_c('v-icon',{attrs:{"color":"#00A2EA"}},[_vm._v(" add_circle ")]),_vm._v(" Custom Name ")],1)],1)],1),_c('v-divider',{staticClass:"mt-2"}),_c('v-list-tile',{on:{"click":function($event){return _vm.dontMapColumn(index)}}},[_c('v-list-tile-content',[_c('v-list-tile-title',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" cancel ")]),_vm._v(" Don't map ")],1)],1)],1)]},proxy:true}],null,true)})],1)}),0)]),_c('tbody',_vm._l((_vm.filteredCsvData),function(row,index){return _c('tr',{key:index,class:[index % 2 ? 'row-even' : 'row-odd']},_vm._l((_vm.headers),function(header,i){return _c('td',{key:i},[_vm._v(" "+_vm._s(row[i])+" ")])}),0)}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }